<template>
  <b-row>
    <div class="nopadding col-sm-5">
      <transition name="slide">
      <b-card class="row">
        <div slot="header"><strong><i class="fa fa-list" aria-hidden="true"></i> {{$t('common.return_list')}}</strong></div>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="company" slot-scope="data">
            <label v-if="data.item.company">{{data.item.company.name}}</label>
            <label v-else></label>
          </template>
          <template slot="object" slot-scope="data">
            <label v-if="data.item.object">{{data.item.object.name}}</label>
            <label v-else></label>
          </template>
          <template slot="client" slot-scope="data">
            <label v-if="data.item.client">{{data.item.client.name}}</label>
            <label v-else></label>
          </template>
          <template slot="total" slot-scope="data">
            <label v-if="data.item.total>0">{{formatPrice(data.item.total)}}</label>
            <label v-else>0</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getDeliveryStatus(data.item.status)}}</b-badge>
          </template>

          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="bill" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="primary" @click="return_bill_pdf(data.item.id,5)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill A5</b-button>
              <b-button variant="primary" @click="return_bill_pdf(data.item.id,4)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill A4</b-button>
            </b-button-group>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)">
                <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="row" v-show="false">
          <b-col>
            <pager class="pull-right" :frame="8"
                  :pageCount="page_count"
                  :page="page"
                  @change="change_page">
            </pager>
          </b-col>
        </div>
      </b-card>
      </transition>
    </div>
    <div class="nopadding col-sm-7">
      <div class="col">
        <div class="row">
          <div class="col bg-info">
            <div class="row mt-1">
              <div class="col-6 h5">
                <i class="fa fa-user" aria-hidden="true"></i>  {{$t('common.project')}} <label class="text-danger" v-if="item.name!=null">[{{item.name}}]</label>
              </div>
              <div class="col-6">
                <strong class="pull-right text-white h4 form-fixer"><i class="fa fa-money 2x"
                aria-hidden="true"></i> {{formatPrice(total_recal)}} {{$t('common.money_icon')}}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="row bg-secondary">
          <div class="col">
            <div class="row">
              <div class="col form-fixer">
                <b-form-group>
                  <label for="ddl_project_list">{{$t('common.project')}}</label>
                  <div v-if="$v.item.object_id.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.item.object_id.required">{{$t('common.project_required')}}</div>
                  </div>
                  <b-form-select id="ddl_project_list" size="sm"
                    @change="onProjectChange(item.object_id)"
                    v-model.trim="item.object_id"
                    :placeholder="$t('common.select_project')">
                    <option value="null" disabled>{{$t('common.select_project')}}</option>
                    <option v-for="c in ls_object_has_order_ddl" :value="c.id"
                    v-bind:key="c.id">{{c.name}}</option>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col form-fixer">
                <b-form-group>
                  <label for="input_return_date">{{$t('common.date')}}</label>
                  <div v-if="$v.item.date.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.item.date.required">{{$t('common.return_day_required')}}</div>
                  </div>
                  <date-picker v-model="item.date" :config="options_datetime" class="date-picker-height"></date-picker>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col form-fixer">
                <label for="txt_return_staff">{{$t('common.staff')}}</label>
                <div v-if="$v.item.staff_id.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.item.staff_id.required">{{$t('common.staff_required')}}</div>
                </div>
                <b-form-select id="ddl_staff_list"
                  v-model.trim="item.staff_id" size="sm"
                  :placeholder="$t('common.select_staff')">
                    <option value="null" disabled>{{$t('common.select_staff')}}</option>
                    <option v-for="c in ls_staff_ddl" :value="c.id"
                    v-bind:key="c.id">{{c.name}}</option>
                </b-form-select>
              </div>
              <div class="col form-fixer">
                <label for="txt_discount">{{$t('common.discount')}}</label>
                <b-form-group>
                  <money v-model.trim="item.discount" v-bind="money_discount" class="form-control text-success"
                    id="'txt_discount" maxLength="4" :disabled="true"></money>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col form-fixer">
                <label for="txt_return_reason">{{$t('common.reason')}}</label>
                  <textarea class="form-control" id="txt_return_reason"
                  v-model.trim="item.reason" maxlength="300" size="sm"
                  :placeholder="$t('common.placeholder_reason')"></textarea>
              </div>  
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <fieldset class="scheduler-border col-md-12">
                <legend class="scheduler-border h4">{{$t('common.product_list')}}</legend>
                <div class="div-scroll row">
                  <div class="col">
                    <div class="row" v-for="(itm, k) in $v.item.return_detail.$each.$iter"
                      :key="k" :class="['', (itm.status.$model>-1?(k%2==0?'bg-light':''):'bg-secondary')]">
                      <div class="col">
                        <div class="row">
                          <div class="form-group col-md-3 form-fixer-list">
                            <label :for="'txt_product_'+k">{{$t('common.product')}}</label>
                            <div v-if="$v.item.return_detail.$error" class="pull-right">
                              <div class="text-danger" v-if="!itm.product_id.required">{{$t('common.product_required')}}</div>
                              <div class="text-danger" v-else-if="!itm.product_id.isUnique">{{$t('common.product_existing')}}</div>
                            </div>
                            <b-form-select v-model.trim="itm.product_id.$model"
                              :id="'ddl_product_'+k" v-on:input="itm.product_id.$touch"
                              :disabled="item.object_id==null"
                              @change="onProductChange(k,itm.$model)" size="sm">
                              <option value="null" disabled>{{$t('common.select_product')}}</option>
                              <option v-for="c in ls_order_object_product_ddl" :value="c.id"
                              v-bind:key="c.id">{{c.name}} - [{{c.supply.name}}]</option>
                            </b-form-select>
                          </div>
                          <div class="form-group col-md-2 form-fixer-list">
                            <label :for="'ddl_unit_'+k">{{$t('common.unit')}}</label>
                            <div v-if="$v.item.return_detail.$error" class="pull-right">
                              <div class="text-danger" v-if="!itm.unit_id.required">{{$t('common.unit_required')}}</div>
                            </div>
                            <b-form-select :id="'ddl_unit_'+k" :disabled="itm.product_id.$model==null"
                            v-model="itm.unit_id.$model" @change="onUnitChange(k,itm.unit_id.$model)" size="sm">
                                <option value="null" disabled>{{$t('common.select_unit')}}</option>
                                <option v-for="d in item.return_detail[k].ls_unit_ddl" :value="d.id"
                                    v-bind:key="d.id">{{JSON.parse(d.name)[$i18n.locale]}}</option>
                            </b-form-select>
                          </div>
                          <div class="form-group col-md-2 form-fixer-list">
                            <label :for="'txt_price_'+k">{{$t('common.price')}}</label>
                            <div v-if="$v.item.return_detail.$error" class="pull-right">
                              <div class="text-danger" v-if="!itm.price.required">{{$t('common.price_required')}}</div>
                            </div>
                            <money v-model.trim="itm.price.$model" v-bind="money" class="form-control"
                            :name="'txt_price_'+k" :id="'txt_price_'+k" :disabled="itm.product_id.$model==null ||itm.unit_id.$model==null"
                            @change.native="onPriceChange(k,itm.price.$model)"
                            maxLength="15"></money>
                          </div>
                          <div class="form-group col-md-2 form-fixer-list">
                            <label :for="'txt_quantity_'+k">{{$t('common.quantity')}}</label>
                            <div v-if="$v.item.return_detail.$error && itm.product_id.$model!=null" class="pull-right">
                              <div class="text-danger" v-if="!itm.quantity.required">{{$t('common.quantity_required')}}</div>
                              <div class="text-danger" v-else-if="!itm.quantity.numeric">{{$t('common.quantity_number_only')}}</div>
                              <div class="text-danger" v-else-if="!itm.quantity.minValue">{{$t('common.greater_than_zero')}}</div>
                            </div>
                            <b-form-input type="number" @change="onQualityChange(k,itm.quantity.$model)"
                              v-model.trim="itm.quantity.$model" class="form-control" size="sm"
                              :name="'txt_quantity_'+k" :id="'txt_quantity_'+k" :disabled="itm.product_id.$model==null ||itm.unit_id.$model==null"
                              maxLength="15" :placeholder="$t('common.placeholder_quantity')"></b-form-input>
                          </div>
                          <div class="form-group col-md-2 form-fixer-list">
                            <label :for="'txt_total_'+k">{{$t('common.total')}}</label>
                            <div v-if="$v.item.return_detail.$error" class="pull-right">
                              <div class="text-danger" v-if="!itm.total.required">{{$t('common.total_required')}}</div>
                              <div class="text-danger" v-else-if="!itm.total.numeric">{{$t('common.total_number_only')}}</div>
                            </div>
                            <money v-model.trim="itm.total.$model" v-bind="money"  class="form-control"
                            :name="'txt_total_'+k" :id="'txt_total_'+k" :disabled="itm.product_id.$model==null ||itm.unit_id.$model==null"
                            maxLength="15"></money>
                          </div>
                          <div class="col-md-1 pull-right text-white form-inline" v-if="itm.status.$model>-1">
                            <div v-if="item.id==null">
                              <a class="btn btn-danger mr-1 btn-sm" v-if="item.return_detail.length>0"
                                @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                              <a class="btn btn-success btn-sm" @click="addNewRow(item.order_id)"><i class="fa fa-plus" aria-hidden="true"></i></a>
                            </div>
                            <div v-else>
                              <a class="btn btn-danger mr-1 btn-sm form-inline"
                              @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                              <a class="btn btn-success btn-sm" @click="addNewRow(item.order_id)"><i class="fa fa-plus" aria-hidden="true"></i></a>
                            </div>
                          </div>
                          <div class="col-md-1 pull-right text-white form-inline" v-else>
                            <a class="btn btn-success mr-1 btn-sm" v-if="item.return_detail.length>0"
                              @click="itm.status.$model=0"><i class="fa fa-refresh" aria-hidden="true"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="row">
          <b-button class="mr-1" variant="outline-primary" @click.prevent="save_return()" size="sm"><span class="icon is-small">
            <i class="fa fa-check"></i> {{$t('common.button.save')}} </span></b-button>
          <b-button variant="outline-success" @click.prevent="clear();addNewRow(item.order_id);refresh()" size="sm"><span class="icon is-small">
            <i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</span></b-button>
        </div>
      </div>
    </div>
    <b-modal id="bill_viewModal" :title="$t('common.view_bill')" size="lg" header-bg-variant="info" no-body ok-only>
      <div id="pawn_return_bill_pdf" style="height:450px;"></div>
    </b-modal>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }
  .div-scroll {
    max-height:500px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width:auto;
    padding:0 10px;
    border-bottom:none;
  }
</style>
<script>
  import pdfobj from 'pdfobject'
  import { validationMixin } from 'vuelidate'
  import { required,requiredIf, numeric } from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'
  import datePicker from 'vue-bootstrap-datetimepicker'
  import moment from 'moment'
  import Pager from '../Pager'
  import { ModelSelect } from 'vue-search-select'
  import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
  import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
  import { Role } from '@/helpers/role.js'
  const greaterThanZero = (value) => value > 0

  export default {
    components: { datePicker,ModelSelect,Pager,VueBootstrapTypeahead},
    mixins: [validationMixin],
    props: {
      hover: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        activetab: 1,
        processing:false,
        money_discount: {
          decimal: ',',
          thousands: '.',
          prefix: '% ',
          precision: 0,
          masked: false
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '$ ',
          precision: 0,
          masked: false
        },
        item: {
          id:null,
          discount:0,
          client_id:null,
          order_id:null,
          staff_id:null,
          object_id:null,
          return_detail:[],
          user_create_id:null,
          user_update_id:null,
          total:0,
          date:moment().format('DD-MM-YYYY HH:mm:ss'),
          company_id:null,
          business:null,
          reason:null,
          branch_id:null,
          code:moment().valueOf().toString()
        },
        options: {
          format: 'DD-MM-YYYY',
          useCurrent: true,
          showClear: true,
          showClose: true
        },
        options_datetime: {
          format: 'DD-MM-YYYY HH:mm:ss',
          useCurrent: true,
          showClear: true,
          showClose: true
        }
      }
    },
    validations: {
      item: {
        date:{
          required
        },
        object_id:{
          required
        },
        staff_id:{
          required
        },
        return_detail: {
          $each: {
            product_id:{
              required: requiredIf(function () {
                return this.item.return_detail.length>0&&this.item.object_id!=null
              }),
              async isUnique(value,itm) {
                debugger
                if(this.item.return_detail.length<=0) return Boolean(true)
                let arr=this.item.return_detail.filter(x => (x.unit_id == itm.unit_id && x.product_id==itm.product_id))
                return Boolean(arr.length<=1)
              }
            },
            unit_id:{
              required: requiredIf(function (itm) {
                return this.item.return_detail.length>0 && itm.product_id!=null
              })
            },
            quantity:{
              minValue:greaterThanZero,
              required: requiredIf(function () {
                return this.item.return_detail.length>0
              }),
              numeric
            },
            total:{
              required: requiredIf(function () {
                return this.item.return_detail.length>0
              }),
              numeric
            },
            price:{
              required: requiredIf(function () {
                return this.item.return_detail.length>0
              }),
              numeric
            },
            status:{},
            ls_unit_ddl:[]
          }
        }
      }
    },
    computed: {
      ...mapGetters('st_return', ['ls_return','per_page','filter','page_count','page']),
      fields(){
        let fields=[]

        let user=this.$store.state.st_authentication.user
        if(user.role==Role.Super_Admin){
          fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
        }else if(user.role==Role.Admin){
          fields.push({key: 'branch',label:this.$t('common.branch'),sortable: true, variant:'info'})
        }

        fields.push({key: 'object',label:this.$t('common.project'),sortable: true})
        fields.push({key: 'client',label:this.$t('common.client'),sortable: true})
        fields.push({key: 'total',label:this.$t('common.total'),sortable: true})
        fields.push({key: 'status',label:this.$t('common.status'),sortable: true})
        fields.push({key: 'bill',label:this.$t('common.bill'),sortable: false})
        fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})

        if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
          fields.push({key: 'action',label:'',sortable: false})
        }

        return fields
      },
      total_recal(){
        let temp=_.sumBy(this.item.return_detail.filter(x=>x.status>=0),"total")
        return parseInt(temp-((temp)*(this.item.discount/100)))
      },
      ls_order_object_product_ddl:{
        get: function(){
          return this.$store.state.st_product.ls_order_object_product_ddl
        },
        set: function(data){
          this.$store.commit('st_product/set_ls_order_object_product_ddl',data)
        }
      },
      is_super () {
        let user= this.$store.state.st_authentication.user
        return user.role==Role.Super_Admin
      },
      business () {
        let user= this.$store.state.st_authentication.user
        return user.company.business
      },
      user_login () {
        return this.$store.state.st_authentication.user
      },
      filter:{
        get: function(){
          return this.$store.state.st_return.filter
        },
        set: function(text){
          this.$store.commit('st_return/set_filter',text)
        }
      },
      ls_unit_ddl(){
        return this.$store.state.st_unit.ls_unit_ddl
      },

      filteredAndSortedData:{
        get: function(){
          let vm=this
          let result = vm.$store.state.st_return.ls_return
          if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
          }

          return result
        }
      },
      ls_object_has_order_ddl(){
        return this.$store.state.st_object.ls_object_has_order_ddl
      },
      ls_staff_ddl(){
        return this.$store.state.st_staff.ls_staff_ddl
      }
    },
    methods: {
      ...mapActions('st_return', ['get_ls_return','apply_filter','change_page']),
      ...mapActions('st_object', ['get_ls_object_has_order_ddl']),
      ...mapActions('st_unit', ['get_ls_unit_ddl']),
      ...mapActions('st_product', ['get_ls_order_object_product_ddl']),
      ...mapActions('st_staff', ['get_ls_staff_ddl']),

      rowDeleted(item, type){
        if (!item || type !== 'row') return
        if (item.status === -1) return 'table-danger'
      },
      return_bill_pdf(id,type){
        let app=this
        var loader = app.$loading.show()
        app.$store.dispatch('st_return/bill_pdf',{id,type,business:app.business,lang:app.$i18n.locale}).then((response) => {
          if(response && response.data){
            let blob = new Blob([response.data], { type: 'application/pdf' })
            pdfobj.embed(window.URL.createObjectURL(blob), "#pawn_return_bill_pdf")
          }

          loader.hide()
        })
      },
      onProjectChange(object_id){
        debugger
        let obj=this.ls_object_has_order_ddl.find(x=>x.id==object_id)
        if(!obj) return
        this.item.order_id=obj.order_id
        this.item.client_id=obj.client_id
        this.get_ls_order_object_product_ddl({company_id:this.user_login.company_id,branch_id:this.user_login.branch_id,object_id:object_id,order_id:obj.order_id})
      },
      onProductChange(k,itm_detail){
        if(itm_detail==null) return
        debugger
        let product=this.ls_order_object_product_ddl.find(x => x.id === itm_detail.product_id)
        k=parseInt(k)
        if(product){
          var filteredArray = this.ls_unit_ddl.filter(function(array_el){
            return product.product_units.filter(function(anotherOne_el){
              if(anotherOne_el.unit_id == array_el.id){
                array_el.buy_price= anotherOne_el.buy_price
                array_el.sell_price= anotherOne_el.sell_price
              }
              return anotherOne_el.unit_id == array_el.id
            }).length > 0
          })

          if(filteredArray.length<=0){
            filteredArray=this.ls_order_object_product_ddl
          }

          this.item.return_detail[k].ls_unit_ddl=filteredArray
          this.item.return_detail[k].price=product.sell_price
        }else{
          this.item.return_detail[k].price=0
        }

        this.item.return_detail[k].quantity=0
        this.item.return_detail[k].total=0
        this.item.return_detail[k].unit_id=null
      },
      onUnitChange(k,unit_id){
        if(unit_id==null) return
        debugger
        k=parseInt(k)
        let unit=this.item.return_detail[k].ls_unit_ddl.find(x=>x.id==unit_id)
        this.item.return_detail[k].quantity=1
        if(unit){
          this.item.return_detail[k].total=this.item.return_detail[k].price=unit.sell_price
        }
      },
      onPriceChange(k,event){
        if(event==null) return
        k=parseInt(k)
        if(this.item&&this.item.return_detail[k].quantity>0){
          this.item.return_detail[k].total=parseInt(this.item.return_detail[k].quantity) * parseInt(this.item.return_detail[k].price)
        }
      },
      onQualityChange(k,event){
        if(event==null) return
        k=parseInt(k)
        if(this.item.return_detail[k].quantity>0){
          this.item.return_detail[k].total=parseInt(this.item.return_detail[k].quantity) * parseInt(this.item.return_detail[k].price)
        }else{
          this.item.return_detail[k].total=0
        }
      },
      addNewRow(order_id) {
        this.item.return_detail.push({
          id:null,
          product_id:null,
          price:0,
          status:0,
          total:0,
          ls_unit_ddl:[],
          unit_id:null,
          order_id:order_id,
          quantity:0,
          user_create_id:this.user_login.id,
          user_update_id:null,
          company_id:this.user_login.company_id,
          branch_id:this.user_login.branch_id,
          business:this.user_login.company.business
        })
      },
      removeRow(k){
        if(k==null || parseInt(k)<0) return
        let itm=this.item.return_detail[k]
        if(itm && itm.id){
          this.confirmRemoveDetail(k)
        }else{
          this.item.return_detail.splice(k, 1)
        }
      },

      confirmRemoveDetail(k) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      }).then(value => {
          if(value!=true) return
          this.item.return_detail[k].status=-1
        })
        .catch(err => {
          this.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
      getRowCount (items) {
        return items.length
      },

      async rowClicked (item) {
        if(item==null || item.status<0) return
        let ls_product=await this.get_ls_order_object_product_ddl({company_id:this.user_login.company_id,branch_id:this.user_login.branch_id,object_id:item.object_id,order_id:item.order_id})
        if(ls_product&&ls_product.data.length>0){
          this.item=item
          this.ls_order_object_product_ddl=ls_product
          debugger
          for (var i = 0; i < item.return_detail.length; i++) {
            let element = item.return_detail[i]
            let product=this.ls_order_object_product_ddl.find(x=>x.id==element.product_id)
            if(product){
              var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
                return product.product_units.filter(function(anotherOne_el){
                  if(anotherOne_el.unit_id == array_el.id){
                    array_el.buy_price= anotherOne_el.buy_price
                    array_el.sell_price= anotherOne_el.sell_price
                  }
                  return anotherOne_el.unit_id == array_el.id
                }).length > 0
              })

              if(filteredArray.length<=0){
                filteredArray=this.ls_order_object_product_ddl
              }

              element.ls_unit_ddl=filteredArray
            }
          }
        }
      },
      refresh(){
        this.$v.$reset()
      },
      clear() {
        debugger
        this.item={id:null,branch_id:null,return_detail:[],order_id:null,
        staff_id:null,reason:null,
        company_id:null,object_id:null,user_create_id:this.user_login.id,client_id:null,
        discount:this.user_login.company.discount,date:moment().format('DD-MM-YYYY HH:mm:ss'),
        user_update_id:null,business:null,code:moment().valueOf().toString(),total:0}
      },
      _validations () {
        return new Promise(resolve => {
          if (this.$v.$error || !this.$v.$pending) {
              return resolve()
          }
          let poll = setInterval(() => {
          if (!this.$v.$pending) {
              clearInterval(poll)
              resolve()
          }
          }, 200)
        })
      },
      async isValidReturn () {
        this.$v.item.$reset()
        this.$v.item.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.item.$error)
      },

      async save_return() {
        var app = this
        debugger
        const isValid = await this.isValidReturn()
        if (!isValid) return
        if (app.processing === true) return

        app.processing = true

        if(app.item.id){
          app.item.user_update_id=app.user_login.id
          app.item.total=app.total_recal
          app.$store.dispatch('st_return/update',app.item)
          .then(function (resp) {
            app.get_ls_return()
            app.clear()
            app.addNewRow(app.item.order_id)
            app.refresh()
            app.$toastr.success(app.$t('common.updated_success'),app.$t("common.message"))
            app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
            app.processing = false
          })
        }else{
          app.item.user_create_id=app.user_login.id
          app.item.business=app.user_login.company.business
          app.item.company_id=app.user_login.company_id
          app.item.branch_id=app.user_login.branch_id
          app.item.total=app.total_recal
          app.item.out_time=moment().format("HH:mm")
          app.$store.dispatch('st_return/create',app.item)
          .then(function (resp) {
            if(resp.status==false){
              app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
              app.processing = false
              return
            }
            app.get_ls_return()
            app.clear()
            app.addNewRow(app.item.order_id)
            app.refresh()
            app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
            app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
            app.processing = false
          })
        }
      },

      async recoverObj(obj){
        let app=this
        if(obj.status>=0) return
        let co=await app.$store.dispatch('st_return/recover_obj',obj.id)
        if(co && co.data.ok){
          app.get_ls_return()
          app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },

      confirmDelete(obj) {
        let app=this
        if(obj.status<0) return
        if(obj.status==0) {
          app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.return")}),app.$t("common.message"))
          return
        }
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      }).then(value => {
          if(value!=true) return
          this.deleteObj(obj)
        })
        .catch(err => {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
      async deleteObj(obj) {
        let app=this
        let co=await app.$store.dispatch('st_return/delete_obj',obj.id)
        if(co&&co.data.ok){
          app.get_ls_return()
          app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },

      searchLike:function(item){
        return item.created_at.includes(this.filter)
        || item.updated_at&&item.updated_at.includes(this.filter)
        || item.branch&&item.branch.name.includes(this.filter)
        || item.company.name.includes(this.filter)
        || item.object&&item.object.name.includes(this.filter)
        || item.client&&item.client.name.includes(this.filter)
        || item.total&&item.total.toString().includes(this.filter)
        || item.staff&&item.staff.name.includes(this.filter)
        || item.date&&item.date.includes(this.filter)
      }
    },
    mounted () {
      let vm =this
      vm.item.discount=vm.user_login.company.discount
      vm.addNewRow(vm.item.order_id)
      vm.get_ls_staff_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id})
      vm.get_ls_return()
      vm.get_ls_object_has_order_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id})
      vm.get_ls_unit_ddl({company_id:vm.user_login.company_id,business:vm.user_login.company.business,lang:vm.$i18n.locale})
    }
  }
</script>
